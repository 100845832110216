import { Component, OnInit } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms'
import {
  FormInputComponent,
  SharedModule,
  IntCustomFieldConfig,
  StringCustomFieldConfig,
} from '@vendure/admin-ui/core'

@Component({
  selector: 'discount-tiers-input',
  templateUrl: './discount-tiers-input.component.html',
  standalone: true,
  imports: [SharedModule],
})
export class DiscountTiersInputComponent
  implements FormInputComponent<StringCustomFieldConfig>, OnInit
{
  readonly: boolean
  formControl: FormControl<any>
  config: StringCustomFieldConfig

  tiers: { quantity: string | null; price: number | null }[] = [
    {
      quantity: null,
      price: null,
    },
  ]

  ngOnInit(): void {
    if (this.formControl.value) {
      this.tiers = this.formControl.value
    }
  }

  onChange = (value: any) => {
    this.formControl.setValue(value)
  }

  addTier() {
    this.tiers.push({ quantity: null, price: null })
    this.onChange(this.tiers)
  }

  removeTier(index: number) {
    this.tiers.splice(index, 1)
    this.onChange(this.tiers)
  }

  changeTierQuantity(index: number, event: InputEvent) {
    const quantity = (event.target as HTMLInputElement).value
    this.tiers[index].quantity = quantity
    this.onChange(this.tiers)
  }

  changeTierPrice(index: number, event: InputEvent) {
    const price = parseFloat((event.target as HTMLInputElement).value) * 100
    this.tiers[index].price = price
    this.onChange(this.tiers)
  }
}
