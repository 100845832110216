import { NgModule } from '@angular/core'
import {
  registerFormInputComponent,
  SharedModule,
  registerCustomDetailComponent,
} from '@vendure/admin-ui/core'
import { VoyadoService } from './voyado.service'
import { StopComponent } from './components/stop.component'
import { StopCustomFieldsComponent } from './components/stop-customFields'

@NgModule({
  imports: [SharedModule],
  providers: [
    registerCustomDetailComponent({
      locationId: 'order-detail',
      component: StopComponent,
    }),
    registerFormInputComponent('stop-component', StopCustomFieldsComponent),
    VoyadoService,
  ],
})
export class StopOrderDetailModule {}
