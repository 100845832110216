<table>
  <thead>
    <tr>
      <th>{{ 'discount-tiers.quantity-for-discount' | translate }}</th>
      <th>{{ 'discount-tiers.total-for-discount' | translate }}</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let tier of tiers; let i = index">
      <td>
        <input
          type="number"
          [(ngModel)]="tier.quantity"
          (change)="changeTierQuantity(i, $event)"
          min="1"
          required
        />
      </td>
      <td>
        <vdr-currency-input
          [(ngModel)]="tier.price"
          currencyCode="SEK"
          (change)="changeTierPrice(i, $event)"
        ></vdr-currency-input>
      </td>
      <td>
        <button class="button-small danger" (click)="removeTier(i)">
          <clr-icon shape="remove"></clr-icon>
          {{ 'common.remove' | translate }}
        </button>
      </td>
    </tr>
  </tbody>
</table>
<button class="button primary" (click)="addTier()">
  {{ 'discount-tiers.add-tier' | translate }}
</button>
