export const extensionRoutes = [  {
    path: 'extensions/export-orders',
    loadChildren: () => import('./extensions/9eebd58641751056fb6144e25e84c3eb1664074717c3cf9a9c891039ad018ff6/order-export.module').then(m => m.OrderExportModule),
  },
  {
    path: 'extensions/badges',
    loadChildren: () => import('./extensions/772da8a2eb7b798b3e06c08ad1cf0c9ad11b51992564ad9e11188b9c586a3d98/badge.module').then(m => m.BadgeModule),
  },
  {
    path: 'extensions/delivery-widget',
    loadChildren: () => import('./extensions/delivery-widget-ui/routes'),
  },
  {
    path: 'extensions/missed-deliveries-widget',
    loadChildren: () => import('./extensions/missed-deliveries-widget-ui/routes'),
  }];
