<div style="display: flex; flex-direction: column; gap: 16px">
  <vdr-card *ngIf="stop" style="grid-column: 1 / -1; margin-bottom: 1rem">
    <div class="title-row">
      <div class="title">Stopp</div>
    </div>

    <div class="form-grid">
      <div>
        Valt stopp: <br />
        {{ stop.streetName }} {{ stop.streetNumber }}<br />{{ stop.zipCode }} {{ stop.city }}
      </div>
      <div>
        Valt upphämtningstillfälle: <br />
        {{ nicePickUpTime }}
      </div>
      <div>
        HashID: <br />
        {{ stop.stopHash }}
      </div>
      <div>
        DepotID: <br />
        {{ stop.depotId }}
      </div>
      <div>
        Depot name: <br />
        {{ stop.depotName }}
      </div>
      <div>
        Route name: <br />
        {{ stop.routeName }}
      </div>
    </div>
    <Button class="button primary edit-button" *ngIf="!editMode" (click)="enableEditMode()">
      Ändra stopp
    </Button>

    <div *ngIf="editMode" class="edit-form">
      <div class="select-wrapper">
        <label>Ändra stopp:</label>
        <select (change)="onStopSelected($event.target.value)">
          <option *ngFor="let stop of nearestStops" [value]="stop.stopHash">
            {{ stop.streetName }} {{ stop.streetNumber }} (+{{ stop.distance | number : '1.0-0' }}m)
            [{{ stop.stopHash }}]
          </option>
        </select>
      </div>

      <div class="select-wrapper">
        <label>Ändra datum:</label>
        <select [(ngModel)]="selectedTime" (change)="onTimeSelected($event.target.value)">
          <option value="null" disabled selected>Välj ett datum</option>
          <option *ngFor="let time of nextTimes" [value]="time.value">
            {{ time.label }}
          </option>
        </select>
      </div>
      <p>Vid ändring av leveranstid/datum/stopp behöver du manuellt meddela detta till kunden.</p>
      <div class="button-group">
        <Button class="button primary" (click)="saveStop()"> Spara nytt stopp/datum </Button>
        <Button class="button secondary" (click)="closeEditMode()"> Avbryt </Button>
      </div>
    </div>
  </vdr-card>
</div>