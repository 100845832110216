import { Component, OnInit, ChangeDetectorRef } from '@angular/core'
import { Observable, firstValueFrom } from 'rxjs'
import { FormGroup } from '@angular/forms'
import {
  SharedModule,
  CustomDetailComponent,
  GetOrderQuery,
  DataService,
  NotificationService,
} from '@vendure/admin-ui/core'
import { VoyadoService } from '../voyado.service'
import dayjs from 'dayjs'
import { graphql } from '../gql/gql'

interface Stop {
  stopHash: string
  streetName: string
  streetNumber: string
  depotId: string
  zipCode: string
  city: string
  latitude: number
  longitude: number
  nextDate: string
  pickUpTime?: string
  routeName?: string
  depotName?: string
}

interface TimeOption {
  value: string
  label: string
}

const updateStopMutation = graphql(`
  mutation updateStop($input: UpdateStopDataInput!) {
    updateStop(input: $input) {
      id
      customFields {
        stop
      }
    }
  }
`)

@Component({
  templateUrl: './stop.component.html',
  styles: [
    `
      .title {
        font-size: 16px;
        margin-bottom: var(--card-padding);
      }

      .edit-button {
        margin-top: 1rem;
      }
      .select-wrapper {
        display: flex;
        flex-direction: column;
      }
      .edit-form {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      .button-group {
        margin-top: 1rem;

        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    `,
  ],

  standalone: true,
  imports: [SharedModule],
})
export class StopComponent implements CustomDetailComponent, OnInit {
  entity$: Observable<GetOrderQuery>
  orderId: string
  detailForm: FormGroup
  nextTimes: TimeOption[] = []
  nearestStops: Omit<Stop, 'pickUpTime'>[] = []
  stop: Stop
  nicePickUpTime: string
  extraInfo$: Observable<any>
  editMode: boolean = false
  selectedTime: string | null = null
  newStop: Stop | null = null

  constructor(
    private voyadoService: VoyadoService,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    private notificationService: NotificationService,
  ) {}

  async ngOnInit() {
    try {
      const order = await firstValueFrom(this.entity$)
      this.initializeStopData(order)
    } catch (error) {
      console.error('Error fetching order:', error)
    }
  }

  async initializeStopData(order: GetOrderQuery) {
    this.orderId = (order as any).id
    this.stop = JSON.parse((order as any)?.customFields?.stop)
    this.nicePickUpTime = dayjs(this.stop.pickUpTime).format('YYYY-MM-DD HH:mm')
  }

  async showEditStopModal() {
    const { latitude, longitude } = await this.fetchLocationForHash(this.stop.stopHash)
    this.nearestStops = await this.fetchNearestHashedStops({ latitude, longitude })
    await this.fetchTimesForHashedStopId(this.stop.stopHash)
  }
  async fetchTimesForHashedStopId(hashId: string) {
    try {
      const times = await this.voyadoService.getNextTimesForHashedStopId(hashId)
      this.nextTimes = times.map((time) => ({
        value: time,
        label: dayjs(time).format('YYYY-MM-DD HH:mm'),
      }))
      this.cdr.detectChanges()
    } catch (error) {
      console.error('Error fetching times for hashId:', hashId, error)
    }
  }
  fetchLocationForHash(hashId: string) {
    return this.voyadoService.locationForHash(hashId)
  }

  fetchNearestHashedStops({ latitude, longitude, limit = 500 }) {
    return this.voyadoService.getNearestHashedStops({ latitude, longitude, limit })
  }

  async fetchStopData() {
    const { latitude, longitude } = await this.fetchLocationForHash(this.stop.stopHash)
    this.nearestStops = await this.fetchNearestHashedStops({ latitude, longitude })
    await this.fetchTimesForHashedStopId(this.stop.stopHash)
  }

  async enableEditMode() {
    await this.fetchStopData()
    this.newStop = this.stop 
    this.selectedTime = this.stop.pickUpTime || null
    this.editMode = true
    this.cdr.detectChanges()
  }

  closeEditMode() {
    this.editMode = false
    this.newStop = null
    this.selectedTime = null
  }

  onTimeSelected(selectedTime: string | null) {
    this.selectedTime = selectedTime
  }

  async onStopSelected(selectedStopHash: string) {
    const selectedStop = this.nearestStops.find((stop) => stop.stopHash === selectedStopHash)
    if (selectedStop) {
      this.newStop = { ...selectedStop }
      await this.fetchTimesForHashedStopId(selectedStopHash)
      this.selectedTime = null
      this.cdr.detectChanges()
    }
  }

  saveStop() {
    if (!this.newStop) {
      this.notificationService.warning('Vänligen välj ett stopp för att spara.')
      return
    }
    if (!this.selectedTime) {
      this.notificationService.warning('Vänligen välj ett datum för att spara.')
      return
    }

    const newStopData = JSON.stringify({
      ...this.newStop,
      pickUpTime: this.selectedTime,
    })

    this.dataService
      .mutate(updateStopMutation, {
        input: {
          orderId: this.orderId,
          stop: newStopData,
        },
      })
      .subscribe({
        next: async (result) => {
          this.stop = JSON.parse((result.updateStop as any)?.customFields?.stop)
          this.nicePickUpTime = dayjs(this.stop.pickUpTime).format('YYYY-MM-DD HH:mm')
          this.notificationService.success('Stopp har uppdaterats')
          this.closeEditMode()
          this.cdr.markForCheck()
        },
        error: (err) => {
          console.error('Failed to save stop:', err)
        },
      })
  }
}
