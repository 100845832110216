import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderExportNavModule } from './extensions/9eebd58641751056fb6144e25e84c3eb1664074717c3cf9a9c891039ad018ff6/order-export-nav.module';
import { OrderExportActionModule } from './extensions/9eebd58641751056fb6144e25e84c3eb1664074717c3cf9a9c891039ad018ff6/order-export-action.module';
import { StopOrderDetailModule } from './extensions/cd1bdf1bc3fec4f2812bc3953599446547afd112ac24b267ccc238406cd13a1d/stop.module';
import { OrderNotesWidgetModule } from './extensions/b24b66c562d651cc5f185bd85c3e14b6b481d533d66008f73615ae081ad4833a/order-notes-widget.module';
import { BadgesNavModule } from './extensions/772da8a2eb7b798b3e06c08ad1cf0c9ad11b51992564ad9e11188b9c586a3d98/badge-nav.module';

import SharedProviders_4_0 from './extensions/delivery-widget-ui/providers';
import SharedProviders_5_0 from './extensions/missed-deliveries-widget-ui/providers';
import SharedProviders_6_0 from './extensions/25060d3b950b64af8b4ad6def52afa87d4a3e520d8682cdf987342867695f538/providers';


@NgModule({
    imports: [CommonModule, OrderExportNavModule, OrderExportActionModule, StopOrderDetailModule, OrderNotesWidgetModule, BadgesNavModule],
    providers: [...SharedProviders_4_0, ...SharedProviders_5_0, ...SharedProviders_6_0],
})
export class SharedExtensionsModule {}
