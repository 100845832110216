import { Component, OnInit } from '@angular/core'
import { FormControl, FormsModule } from '@angular/forms'
import { SharedModule, FormInputComponent } from '@vendure/admin-ui/core'

@Component({
  template: '', // hide field in admin ui therefore no template
  standalone: true,
  imports: [SharedModule, FormsModule],
})
export class StopCustomFieldsComponent implements FormInputComponent, OnInit {
  readonly: boolean
  config: any
  formControl: FormControl

  constructor() {}

  async ngOnInit() {}
}
